import { defineAsyncComponent } from 'vue'
export default {
  "agent-cash-plus": defineAsyncComponent(() => import("/home/bun/app/layouts/AgentCashPlus.vue").then(m => m.default || m)),
  blocks: defineAsyncComponent(() => import("/home/bun/app/layouts/Blocks.vue").then(m => m.default || m)),
  bonita: defineAsyncComponent(() => import("/home/bun/app/layouts/Bonita.vue").then(m => m.default || m)),
  hilton: defineAsyncComponent(() => import("/home/bun/app/layouts/Hilton.vue").then(m => m.default || m)),
  "hilton-yucatan": defineAsyncComponent(() => import("/home/bun/app/layouts/HiltonYucatan.vue").then(m => m.default || m)),
  "hyatt-zilara": defineAsyncComponent(() => import("/home/bun/app/layouts/HyattZilara.vue").then(m => m.default || m)),
  "hyatt-ziva": defineAsyncComponent(() => import("/home/bun/app/layouts/HyattZiva.vue").then(m => m.default || m)),
  "infinity-luxury": defineAsyncComponent(() => import("/home/bun/app/layouts/InfinityLuxury.vue").then(m => m.default || m)),
  jewel: defineAsyncComponent(() => import("/home/bun/app/layouts/Jewel.vue").then(m => m.default || m)),
  "jewel-grande": defineAsyncComponent(() => import("/home/bun/app/layouts/JewelGrande.vue").then(m => m.default || m)),
  kimpton: defineAsyncComponent(() => import("/home/bun/app/layouts/Kimpton.vue").then(m => m.default || m)),
  "no-chrome": defineAsyncComponent(() => import("/home/bun/app/layouts/NoChrome.vue").then(m => m.default || m)),
  "playa-cares": defineAsyncComponent(() => import("/home/bun/app/layouts/PlayaCares.vue").then(m => m.default || m)),
  "playa-collection": defineAsyncComponent(() => import("/home/bun/app/layouts/PlayaCollection.vue").then(m => m.default || m)),
  playaresorts: defineAsyncComponent(() => import("/home/bun/app/layouts/Playaresorts.vue").then(m => m.default || m)),
  sanctuary: defineAsyncComponent(() => import("/home/bun/app/layouts/Sanctuary.vue").then(m => m.default || m)),
  sanctuary2024: defineAsyncComponent(() => import("/home/bun/app/layouts/Sanctuary2024.vue").then(m => m.default || m)),
  sandpiper: defineAsyncComponent(() => import("/home/bun/app/layouts/Sandpiper.vue").then(m => m.default || m)),
  seadust: defineAsyncComponent(() => import("/home/bun/app/layouts/Seadust.vue").then(m => m.default || m)),
  wyndham: defineAsyncComponent(() => import("/home/bun/app/layouts/Wyndham.vue").then(m => m.default || m)),
  "wyndham-cancun": defineAsyncComponent(() => import("/home/bun/app/layouts/WyndhamCancun.vue").then(m => m.default || m)),
  "wyndham-playa": defineAsyncComponent(() => import("/home/bun/app/layouts/WyndhamPlaya.vue").then(m => m.default || m))
}