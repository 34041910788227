
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_93n8b2LL3PkHMeta } from "/home/bun/app/pages/[...slug].vue?macro=true";
import { default as createwUw5AL6JsuMeta } from "/home/bun/app/pages/voix/booking-code-triggers/create.vue?macro=true";
import { default as _91id_93DQcddPfmQrMeta } from "/home/bun/app/pages/voix/booking-code-triggers/edit/[id].vue?macro=true";
import { default as indexwNCDV78H82Meta } from "/home/bun/app/pages/voix/booking-code-triggers/index.vue?macro=true";
import { default as createu2hTKcSPxMMeta } from "/home/bun/app/pages/voix/brands/create.vue?macro=true";
import { default as _91id_93S71XMnn75JMeta } from "/home/bun/app/pages/voix/brands/edit/[id].vue?macro=true";
import { default as index0OJLxwNWpnMeta } from "/home/bun/app/pages/voix/brands/index.vue?macro=true";
import { default as index4iZrambjxBMeta } from "/home/bun/app/pages/voix/caches/index.vue?macro=true";
import { default as createwGxLTNwZMLMeta } from "/home/bun/app/pages/voix/fact-sheets/create.vue?macro=true";
import { default as _91id_93vXXJ4nPWQbMeta } from "/home/bun/app/pages/voix/fact-sheets/edit/[id].vue?macro=true";
import { default as indexBWPw9hjXdiMeta } from "/home/bun/app/pages/voix/fact-sheets/index.vue?macro=true";
import { default as createjxKj3bFUSMMeta } from "/home/bun/app/pages/voix/menus/create.vue?macro=true";
import { default as _91id_93ooeLDoUXyLMeta } from "/home/bun/app/pages/voix/menus/edit/[id].vue?macro=true";
import { default as indexahz7Cmz9JGMeta } from "/home/bun/app/pages/voix/menus/index.vue?macro=true";
import { default as createLMq4LyrGLaMeta } from "/home/bun/app/pages/voix/posts/create.vue?macro=true";
import { default as _91id_93u3Mpbxf4MNMeta } from "/home/bun/app/pages/voix/posts/edit/[id].vue?macro=true";
import { default as indexwXXCrGBWCgMeta } from "/home/bun/app/pages/voix/posts/index.vue?macro=true";
import { default as createnyo4uejCBhMeta } from "/home/bun/app/pages/voix/resorts/create.vue?macro=true";
import { default as _91id_93IQ99OExv4oMeta } from "/home/bun/app/pages/voix/resorts/edit/[id].vue?macro=true";
import { default as indexrx5pubVA64Meta } from "/home/bun/app/pages/voix/resorts/index.vue?macro=true";
import { default as _91id_93U8e9Tjtp27Meta } from "/home/bun/app/pages/voix/specials/copy/[id].vue?macro=true";
import { default as createzeFEtflXUYMeta } from "/home/bun/app/pages/voix/specials/create.vue?macro=true";
import { default as _91id_93GrMstTzXozMeta } from "/home/bun/app/pages/voix/specials/edit/[id].vue?macro=true";
import { default as indexCJvuSvtMjJMeta } from "/home/bun/app/pages/voix/specials/index.vue?macro=true";
import { default as indexqM8WGPNHygMeta } from "/home/bun/app/pages/voix/style-guide/index.vue?macro=true";
import { default as voix_45debugc3Dy79hZXTMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/voix-debug.vue?macro=true";
import { default as PreviewHVxcz5WebcMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/releases/Preview.vue?macro=true";
import { default as IndexW7y91cjPBYMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/settings-menu/Index.vue?macro=true";
import { default as loginKM8BHtQEEjMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/auth/login.vue?macro=true";
import { default as callbackcZerxeEKxDMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/auth/callback.vue?macro=true";
import { default as studiowqtmfyvkr3Meta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/studio.vue?macro=true";
import { default as IndexckmLMvqBpkMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/head-tags/Index.vue?macro=true";
import { default as EditG1HO8Etx8EMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/head-tags/Edit.vue?macro=true";
import { default as CreateLeIXmd2fkwMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/head-tags/Create.vue?macro=true";
import { default as IndexIDbZAZtpzDMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/pages/Index.vue?macro=true";
import { default as Createw5wtdqOqOrMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/pages/Create.vue?macro=true";
import { default as TranslateIiKIxweMwYMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/pages/Translate.vue?macro=true";
import { default as CopylpBRpaYKgXMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/pages/Copy.vue?macro=true";
import { default as Index4YWKCcvoL4Meta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/releases/Index.vue?macro=true";
import { default as Indexf1ItR7f7qdMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/redirects/Index.vue?macro=true";
import { default as Editae48Oss9zWMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/redirects/Edit.vue?macro=true";
import { default as CreatebN69AIcYbHMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/redirects/Create.vue?macro=true";
import { default as IndexQVnjPyG5BtMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/users/Index.vue?macro=true";
import { default as CreateTOBLRbQQziMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/users/Create.vue?macro=true";
import { default as EditOTvrOnndecMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/users/Edit.vue?macro=true";
import { default as ProfileQhCGEDbhPwMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/users/Profile.vue?macro=true";
import { default as indexsnvtM1ev5PMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/cookbook/index.vue?macro=true";
import { default as SlicesView931jLWZylPMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/components/slicer/views/SlicesView.vue?macro=true";
import { default as TemplateViewmnhMeb15cLMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/components/slicer/views/TemplateView.vue?macro=true";
import { default as DesignGuideViewJFW0wMBTgJMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/components/slicer/views/DesignGuideView.vue?macro=true";
import { default as slicer3IwWXw1DzYMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/slicer.vue?macro=true";
import { default as documentLXQWp7WdzwMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/documentation/document.vue?macro=true";
import { default as voixsxpOelNnQBMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/voix.vue?macro=true";
import { default as SlicePreviewBrpztzHcL8Meta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/components/slicer/views/SlicePreview.vue?macro=true";
import { default as previewaEou2GK5toMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/cookbook/preview.vue?macro=true";
export default [
  {
    name: "slug-en",
    path: "/:slug(.*)*",
    component: () => import("/home/bun/app/pages/[...slug].vue")
  },
  {
    name: "slug-es",
    path: "/es/:slug(.*)*",
    component: () => import("/home/bun/app/pages/[...slug].vue")
  },
  {
    name: "voix-booking-code-triggers-create-en",
    path: "/voix/booking-code-triggers/create",
    component: () => import("/home/bun/app/pages/voix/booking-code-triggers/create.vue")
  },
  {
    name: "voix-booking-code-triggers-create-es",
    path: "/es/voix/booking-code-triggers/create",
    component: () => import("/home/bun/app/pages/voix/booking-code-triggers/create.vue")
  },
  {
    name: "voix-booking-code-triggers-edit-id-en",
    path: "/voix/booking-code-triggers/edit/:id()",
    component: () => import("/home/bun/app/pages/voix/booking-code-triggers/edit/[id].vue")
  },
  {
    name: "voix-booking-code-triggers-edit-id-es",
    path: "/es/voix/booking-code-triggers/edit/:id()",
    component: () => import("/home/bun/app/pages/voix/booking-code-triggers/edit/[id].vue")
  },
  {
    name: "voix-booking-code-triggers-en",
    path: "/voix/booking-code-triggers",
    component: () => import("/home/bun/app/pages/voix/booking-code-triggers/index.vue")
  },
  {
    name: "voix-booking-code-triggers-es",
    path: "/es/voix/booking-code-triggers",
    component: () => import("/home/bun/app/pages/voix/booking-code-triggers/index.vue")
  },
  {
    name: "voix-brands-create-en",
    path: "/voix/brands/create",
    component: () => import("/home/bun/app/pages/voix/brands/create.vue")
  },
  {
    name: "voix-brands-create-es",
    path: "/es/voix/brands/create",
    component: () => import("/home/bun/app/pages/voix/brands/create.vue")
  },
  {
    name: "voix-brands-edit-id-en",
    path: "/voix/brands/edit/:id()",
    component: () => import("/home/bun/app/pages/voix/brands/edit/[id].vue")
  },
  {
    name: "voix-brands-edit-id-es",
    path: "/es/voix/brands/edit/:id()",
    component: () => import("/home/bun/app/pages/voix/brands/edit/[id].vue")
  },
  {
    name: "voix-brands-en",
    path: "/voix/brands",
    component: () => import("/home/bun/app/pages/voix/brands/index.vue")
  },
  {
    name: "voix-brands-es",
    path: "/es/voix/brands",
    component: () => import("/home/bun/app/pages/voix/brands/index.vue")
  },
  {
    name: "voix-caches-en",
    path: "/voix/caches",
    component: () => import("/home/bun/app/pages/voix/caches/index.vue")
  },
  {
    name: "voix-caches-es",
    path: "/es/voix/caches",
    component: () => import("/home/bun/app/pages/voix/caches/index.vue")
  },
  {
    name: "voix-fact-sheets-create-en",
    path: "/voix/fact-sheets/create",
    component: () => import("/home/bun/app/pages/voix/fact-sheets/create.vue")
  },
  {
    name: "voix-fact-sheets-create-es",
    path: "/es/voix/fact-sheets/create",
    component: () => import("/home/bun/app/pages/voix/fact-sheets/create.vue")
  },
  {
    name: "voix-fact-sheets-edit-id-en",
    path: "/voix/fact-sheets/edit/:id()",
    component: () => import("/home/bun/app/pages/voix/fact-sheets/edit/[id].vue")
  },
  {
    name: "voix-fact-sheets-edit-id-es",
    path: "/es/voix/fact-sheets/edit/:id()",
    component: () => import("/home/bun/app/pages/voix/fact-sheets/edit/[id].vue")
  },
  {
    name: "voix-fact-sheets-en",
    path: "/voix/fact-sheets",
    component: () => import("/home/bun/app/pages/voix/fact-sheets/index.vue")
  },
  {
    name: "voix-fact-sheets-es",
    path: "/es/voix/fact-sheets",
    component: () => import("/home/bun/app/pages/voix/fact-sheets/index.vue")
  },
  {
    name: "voix-menus-create-en",
    path: "/voix/menus/create",
    component: () => import("/home/bun/app/pages/voix/menus/create.vue")
  },
  {
    name: "voix-menus-create-es",
    path: "/es/voix/menus/create",
    component: () => import("/home/bun/app/pages/voix/menus/create.vue")
  },
  {
    name: "voix-menus-edit-id-en",
    path: "/voix/menus/edit/:id()",
    component: () => import("/home/bun/app/pages/voix/menus/edit/[id].vue")
  },
  {
    name: "voix-menus-edit-id-es",
    path: "/es/voix/menus/edit/:id()",
    component: () => import("/home/bun/app/pages/voix/menus/edit/[id].vue")
  },
  {
    name: "voix-menus-en",
    path: "/voix/menus",
    component: () => import("/home/bun/app/pages/voix/menus/index.vue")
  },
  {
    name: "voix-menus-es",
    path: "/es/voix/menus",
    component: () => import("/home/bun/app/pages/voix/menus/index.vue")
  },
  {
    name: "voix-posts-create-en",
    path: "/voix/posts/create",
    component: () => import("/home/bun/app/pages/voix/posts/create.vue")
  },
  {
    name: "voix-posts-create-es",
    path: "/es/voix/posts/create",
    component: () => import("/home/bun/app/pages/voix/posts/create.vue")
  },
  {
    name: "voix-posts-edit-id-en",
    path: "/voix/posts/edit/:id()",
    component: () => import("/home/bun/app/pages/voix/posts/edit/[id].vue")
  },
  {
    name: "voix-posts-edit-id-es",
    path: "/es/voix/posts/edit/:id()",
    component: () => import("/home/bun/app/pages/voix/posts/edit/[id].vue")
  },
  {
    name: "voix-posts-en",
    path: "/voix/posts",
    component: () => import("/home/bun/app/pages/voix/posts/index.vue")
  },
  {
    name: "voix-posts-es",
    path: "/es/voix/posts",
    component: () => import("/home/bun/app/pages/voix/posts/index.vue")
  },
  {
    name: "voix-resorts-create-en",
    path: "/voix/resorts/create",
    component: () => import("/home/bun/app/pages/voix/resorts/create.vue")
  },
  {
    name: "voix-resorts-create-es",
    path: "/es/voix/resorts/create",
    component: () => import("/home/bun/app/pages/voix/resorts/create.vue")
  },
  {
    name: "voix-resorts-edit-id-en",
    path: "/voix/resorts/edit/:id()",
    component: () => import("/home/bun/app/pages/voix/resorts/edit/[id].vue")
  },
  {
    name: "voix-resorts-edit-id-es",
    path: "/es/voix/resorts/edit/:id()",
    component: () => import("/home/bun/app/pages/voix/resorts/edit/[id].vue")
  },
  {
    name: "voix-resorts-en",
    path: "/voix/resorts",
    component: () => import("/home/bun/app/pages/voix/resorts/index.vue")
  },
  {
    name: "voix-resorts-es",
    path: "/es/voix/resorts",
    component: () => import("/home/bun/app/pages/voix/resorts/index.vue")
  },
  {
    name: "voix-specials-copy-id-en",
    path: "/voix/specials/copy/:id()",
    component: () => import("/home/bun/app/pages/voix/specials/copy/[id].vue")
  },
  {
    name: "voix-specials-copy-id-es",
    path: "/es/voix/specials/copy/:id()",
    component: () => import("/home/bun/app/pages/voix/specials/copy/[id].vue")
  },
  {
    name: "voix-specials-create-en",
    path: "/voix/specials/create",
    component: () => import("/home/bun/app/pages/voix/specials/create.vue")
  },
  {
    name: "voix-specials-create-es",
    path: "/es/voix/specials/create",
    component: () => import("/home/bun/app/pages/voix/specials/create.vue")
  },
  {
    name: "voix-specials-edit-id-en",
    path: "/voix/specials/edit/:id()",
    component: () => import("/home/bun/app/pages/voix/specials/edit/[id].vue")
  },
  {
    name: "voix-specials-edit-id-es",
    path: "/es/voix/specials/edit/:id()",
    component: () => import("/home/bun/app/pages/voix/specials/edit/[id].vue")
  },
  {
    name: "voix-specials-en",
    path: "/voix/specials",
    component: () => import("/home/bun/app/pages/voix/specials/index.vue")
  },
  {
    name: "voix-specials-es",
    path: "/es/voix/specials",
    component: () => import("/home/bun/app/pages/voix/specials/index.vue")
  },
  {
    name: "voix-style-guide-en",
    path: "/voix/style-guide",
    component: () => import("/home/bun/app/pages/voix/style-guide/index.vue")
  },
  {
    name: "voix-style-guide-es",
    path: "/es/voix/style-guide",
    component: () => import("/home/bun/app/pages/voix/style-guide/index.vue")
  },
  {
    name: "voix-debug",
    path: "/voix-debug",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/voix-debug.vue")
  },
  {
    name: "voix-preview-release-entry-en",
    path: "/preview-release/:releaseId/:previewSecret",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/releases/Preview.vue")
  },
  {
    name: "voix",
    path: "/voix",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/voix.vue"),
    children: [
  {
    name: "voix-settings-en",
    path: "",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/settings-menu/Index.vue")
  },
  {
    name: "voix-login-en",
    path: "login",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/auth/login.vue")
  },
  {
    name: "voix-auth-callback-en",
    path: "auth/callback",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/auth/callback.vue")
  },
  {
    name: "voix-studio-en",
    path: "studio",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/studio.vue")
  },
  {
    name: "voix-settings-head-tags-en",
    path: "head-tags",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/head-tags/Index.vue")
  },
  {
    name: "voix-settings-head-tags-edit-en",
    path: "head-tags/:id",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/head-tags/Edit.vue")
  },
  {
    name: "voix-settings-head-tags-create-en",
    path: "head-tags/create",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/head-tags/Create.vue")
  },
  {
    name: "voix-settings-pages-en",
    path: "pages",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/pages/Index.vue")
  },
  {
    name: "voix-settings-pages-create-en",
    path: "pages/create",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/pages/Create.vue")
  },
  {
    name: "voix-settings-pages-translate-en",
    path: "pages/translate",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/pages/Translate.vue")
  },
  {
    name: "voix-settings-pages-copy-en",
    path: "pages/copy",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/pages/Copy.vue")
  },
  {
    name: "voix-settings-releases-en",
    path: "releases",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/releases/Index.vue")
  },
  {
    name: "voix-settings-redirects-en",
    path: "redirects",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/redirects/Index.vue")
  },
  {
    name: "voix-settings-redirects-edit-en",
    path: "redirects/:id",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/redirects/Edit.vue")
  },
  {
    name: "voix-settings-redirects-create-en",
    path: "redirects/create",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/redirects/Create.vue")
  },
  {
    name: "voix-settings-users-en",
    path: "users",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/users/Index.vue")
  },
  {
    name: "voix-settings-users-create-en",
    path: "users/create",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/users/Create.vue")
  },
  {
    name: "voix-settings-users-edit-en",
    path: "users/:id",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/users/Edit.vue")
  },
  {
    name: "voix-settings-users-profile-en",
    path: "profile",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/users/Profile.vue")
  },
  {
    name: "voix-cookbook-en",
    path: "cookbook",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/cookbook/index.vue")
  },
  {
    name: "voix-slicer-en",
    path: "slicer",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/slicer.vue"),
    children: [
  {
    name: "voix-slicer-slices-en",
    path: "",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/components/slicer/views/SlicesView.vue")
  },
  {
    name: "voix-slicer-template-en",
    path: "templates/:templateName",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/components/slicer/views/TemplateView.vue")
  },
  {
    name: "voix-slicer-design-guide-en",
    path: "design-guide",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/components/slicer/views/DesignGuideView.vue")
  }
]
  },
  {
    name: "voix-documentation-en",
    path: "documentation/:documentPath(.*)?",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/documentation/document.vue")
  }
]
  },
  {
    name: "slicer-slice-en",
    path: "/voix/slice-preview/:sliceName",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/components/slicer/views/SlicePreview.vue")
  },
  {
    name: "cookbook-preview-en",
    path: "/voix/cookbook-preview/:recipe(.*)",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/cookbook/preview.vue")
  }
]